import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllMembers(ctx, queryParams) {
      //console.log(queryParams);
      var limit=queryParams.perPage;
      var page=queryParams.currentPage;
      var que=queryParams.searchQuery;
      limit=limit==undefined||limit==null?50:limit;
      page=page==undefined||page==null?1:page;
      que=que==undefined||que==null?'':que;
         
     var uri=`/admin/f/cm/getmembers?p=${page}&l=${limit}&q=${que}`;
     
//console.log(uri);
      return new Promise((resolve, reject) => {
        axios
        
          .get(uri)

          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/f/cm/getmemberdetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInstructer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getinstructordetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCoursesbyInstructers(ctx,  { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursesbyinstructer/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInstructerStatus(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/updateinstructerstatus', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteInstructer(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/deleteinstructer', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInstructorphoto(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/updateinstructorphoto', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInstructor(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/createinstructor', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createNewMember(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/f/cm//newmember', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    


  },
}
